<template>
  <div class="dashboard-widget-settings">

    <div>В данном виджете отображается самая первая ячейка из полученных данных.</div>
    <div>Остальные данные игнорируются</div>

    <VQBForm
      v-if="localValue.vqb"
      v-model="localValue.vqb"
      :select-fields-limit="1"
    ></VQBForm>
  </div>
</template>

<script>
import Builder from "@/components/report/builder/Builder.vue";
import VQBForm from "@/components/report/builder/VQBForm.vue";

export default {
  name: 'VQBCellWidgetDataSettings',
  components: {VQBForm, Builder},
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    localValue: {
      get() {return this.value},
      set(value) {this.$emit('input', value)}
    }
  },
  data(){
    return {}
  },
  methods: {}
}
</script>