<template>
  <div class="dashboard-widget-settings">

    <el-form
      label-position="left"
      label-width="200px"
      style="width: 500px"
      size="mini"
    >
      <el-form-item label="Размер текста">
        <el-slider v-model="localValue.fontSize" :min="1" :max="200" show-input input-size="mini"></el-slider>
      </el-form-item>
      <el-form-item label="Цвет текста">
        <el-color-picker v-model="localValue.color"></el-color-picker>
      </el-form-item>
      <el-form-item label="Расположение">
        <el-radio-group v-model="localValue.align" size="mini">
          <el-radio-button label="left"><i class="fas fa-align-left"></i></el-radio-button>
          <el-radio-button label="center"><i class="fas fa-align-center"></i></el-radio-button>
          <el-radio-button label="right"><i class="fas fa-align-right"></i></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Padding">
        <el-slider v-model="localValue.padding" :min="0" :max="300" show-input input-size="mini"></el-slider>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import Builder from "@/components/report/builder/Builder.vue";
import VQBForm from "@/components/report/builder/VQBForm.vue";
import VQBCellWidgetDataSettings from "@/components/home/dashboard/widgets/VQBCellWidget/data.vue";

export default {
  name: 'VQBCellWidgetSettings',
  components: {VQBForm, Builder},
  props: {
    additionalTabs: {type: Array},
    value: { type: Object, required: true },
  },
  computed: {
    localValue: {
      get() {return this.value},
      set(value) {this.$emit('input', value)}
    }
  },
  mounted() {
    this.$emit('update:additionalTabs', [...this.additionalTabs, {
      label: 'Настройки данных',
      name: 'data',
      component: VQBCellWidgetDataSettings,
    }])
  },
  data(){
    return {}
  },
  methods: {
  }
}
</script>